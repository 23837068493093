import React, { useMemo } from "react";
import { GetRowIdParams } from "ag-grid-community";
import { GetQuotumUsage_Include, QuotumUsage } from "../../../api/core/index.js";
import { DataGridProps, DataGrid } from "@fifthsun/ui/ag-grid";
import quotumUsageColumnDefs from "./quotumUsageColumnDefs.js";
import useQuotumUsageDatasource, { QuotumUsageDatasourceProps } from "./useQuotumUsageDatasource.js";

export interface QuotumUsageDataGridProps extends DataGridProps {
  include?: GetQuotumUsage_Include[];
}

export const QuotumUsageDataGrid = (props: QuotumUsageDataGridProps) => {
  const { include, ...otherProps } = props;

  const options: QuotumUsageDatasourceProps = {
    include
  };

  const datasource = useQuotumUsageDatasource({ options });

  const defaultGridProps = useMemo<DataGridProps>(() => {
    return {
      columnDefs: quotumUsageColumnDefs,
      rowModelType: "serverSide",
      serverSideDatasource: datasource,
      serverSideInfiniteScroll: true,
      blockLoadDebounceMillis: 100,
      getRowId: (param: GetRowIdParams) => `${(param.data as QuotumUsage).quotumId}-${(param.data as QuotumUsage).date}`,
      animateRows: true,
    };
  }, [datasource]);

  return (
    <DataGrid {...defaultGridProps} {...otherProps} />
  );
};

export default QuotumUsageDataGrid;
