import React, { useMemo } from "react";
import { Collapse, Skeleton } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { BatchStatusButton, LineItemDataGrid, LineItemDataGridProps, Tag } from "../../index.js";
import { FilterModel, GridReadyEvent } from "ag-grid-community";
import LineItemColumnDefs from "./LineItemColumnDefs.js";
import { formatDate } from "@fifthsun/ui/utils";
import { BatchesApi } from "../../../api/core/index.js";
import { isNil } from "lodash";

import "./index.scss";

const { Panel } = Collapse;

export interface BatchDetailProps {
  id?: string;
  isFloorView?: boolean;

  // for unit testing only
  testId?: string | null;
}

export const BatchDetail = (props: BatchDetailProps) => {
  const { id } = props;

  const skip = !id || /^\s*$/.test(id);

  const { data, isLoading, isUninitialized, isSuccess, refetch } = BatchesApi.useGetBatchesQuery({
    filter: {
      id: {
        filterType: "text",
        condition1: {
          type: "equals",
          filter: id
        }
      }
    }
  }, {
    skip,
    refetchOnMountOrArgChange: true
  });

  const defaultFilterModel = useMemo<FilterModel>(() => ({
    batchId: {
      filterType: 'text',
      type: 'equals',
      filter: id,
    }
  }), [id]);

  const lineItemDataGridOptions = useMemo<LineItemDataGridProps>(() => ({
    columnDefs: LineItemColumnDefs,
    include: ["blank", "order", "store", "batch"],
    domLayout: "autoHeight",
    statusBar: undefined,
    sideBar: {
      toolPanels: undefined,
      hiddenByDefault: true
    },
    rowSelection: {
      mode: "multiRow",
      checkboxes: false,
      headerCheckbox: false,
      enableClickSelection: false,
    },
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: false
    },
    onGridReady: (e: GridReadyEvent) => {
      e.api.setFilterModel(defaultFilterModel);
    }
  }), [defaultFilterModel]);

  if (skip || isUninitialized) {
    return <div data-testid={props.testId} />;
  }

  if (isLoading) {
    return <Skeleton data-testid={props.testId} active paragraph={{ rows: 8 }} />;
  }

  if (!data?.rows.length) {
    return <div data-testid={props.testId} />;
  }

  const batch = data.rows[0];

  const listItemDate = (date: Date | null | undefined) => {
    if (!date) {
      return <span className="batch-detail-description-date" />;
    }

    const formattedDate = formatDate(date, { showTime: true });
    const formattedDateDistance = formatDate(date, { onlyDistance: true });

    return (
      <span className="batch-detail-description-date">
        {formattedDate}
        {date && <Tag className="batch-detail-description-date-distance">{formattedDateDistance}</Tag>}
      </span>
    );
  };

  const items = [
    {
      key: "id",
      label: "Internal ID",
      value: batch.id,
    },
    {
      key: "ziftId",
      label: "Zift ID",
      value: batch.ziftId,
    },
    {
      key: "status",
      label: "Status",
      value: batch.status,
    },
    {
      key: "progress",
      label: "Progress",
      value: isNil(batch.progress) ? "" : `${(batch.progress || 0) * 100.0}%`,
    },
    {
      key: "createdAt",
      label: "Batched Date",
      value: listItemDate(batch.createdAt),
    },
    {
      key: "updatedAt",
      label: "Last Update",
      value: listItemDate(batch.updatedAt),
    },
    {
      key: "lastScanAt",
      label: "Last Scan",
      value: listItemDate(batch.lastScanAt),
    },
    {
      key: "completedAt",
      label: "Completed On",
      value: listItemDate(batch.completedAt),
    },
    {
      key: "lastConfirmPullAt",
      label: "Last Pull At",
      value: listItemDate(batch.lastConfirmPullAt),
    },
    {
      key: "lastConfirmPullUserId",
      label: "Last Pull User",
      value: batch.lastConfirmPullUserId,
    },
    {
      key: "pullBox",
      label: "Tote Bin / Pull Box",
      value: batch.pullBox,
    },
    {
      key: "lastConfirmBrandedAt",
      label: "Branding Completed At",
      value: listItemDate(batch.lastConfirmBrandedAt),
    },
    {
      key: "lastConfirmDeliveredToPrinterAt",
      label: "Delivered To Printer At",
      value: listItemDate(batch.lastConfirmDeliveredToPrinterAt),
    },
    {
      key: "note",
      label: "Notes",
      value: batch.note?.content,
    },
  ];

  const actions = [
    <BatchStatusButton
      key="batchStatus"
      batch={batch}
      isFloorView
      onTransition={() => refetch()}
    />
  ];

  // Move from LineItemTable to LineItemDataGrid when time permits
  return (
    <div data-testid={props.testId} style={{ height: '100%', width: '100%' }}>
      <PageHeader style={{ padding: 0, paddingBottom: 15 }} extra={actions}>
        <Collapse key="collapse_details" defaultActiveKey={["details"]}>
          <Panel header="Details" key="details">
            <table className="batch-detail-descriptions">
              <tbody>
                {items.map(({ key, label, value }) => (
                  <tr className="batch-detail-description-item" key={`batch-detail-${id}-${key}`}>
                    <th className="batch-detail-description-item-label">{label}</th>
                    <td className="batch-detail-description-item-content">{value ?? "--"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Panel>
        </Collapse>
      </PageHeader>

      <div style={{ height: '100%', width: '100%' }}>
        { isSuccess && (
          <LineItemDataGrid {...lineItemDataGridOptions} />
        )}
      </div>
    </div>
  );
};
