import { useMemo } from "react";

import { 
  AgGridDatasourceProps, GetStores, GetStoresVariables, GetStoresVariables_Options, 
  GetStoresVariables_Options_Includes, StoresApi, useAgGridDatasource 
} from "../../../api/core/index.js";

export interface StoreDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetStoresVariables_Options, GetStoresVariables, GetStores>, "endpoint"> {
    include?: GetStoresVariables_Options_Includes[] | null | undefined;
  }

export const DefaultStoreDatasourceProps = Object.freeze<StoreDatasourceProps>({
  options: {
    count: true,
    include: ["tag_list"]
  }
});

export function useStoreDatasource(props: StoreDatasourceProps = DefaultStoreDatasourceProps) {
  const datasourceProps = useMemo<AgGridDatasourceProps<GetStoresVariables_Options>>(() => ({
    ...props,
    endpoint: StoresApi.endpoints.getStores,
    options: {
      ...DefaultStoreDatasourceProps.options,
      ...props.options
    },
  }), [props]);

  return useAgGridDatasource<GetStoresVariables_Options>(datasourceProps);
}

export default useStoreDatasource;
