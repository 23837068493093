import React, { useCallback, useMemo } from "react";
import { ColDef, GridOptions, ICellRendererParams } from "ag-grid-enterprise";
import { FirstDataRenderedEvent, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ArrayParam, DateLocalParam, StringParam, useQueryParam, useQueryParams, withDefault } from "@fifthsun/ui/api/queryParams";
import { Form, Select, Spin } from "antd";
import { LocationFacilitySelect } from "../../Facility/index.js";
import { DateLocalRangePicker } from "../../DateLocalRangePicker/index.js";
import { LineItemStatus, LineItemsApi, OrderStatus, StoresApi, Waypoint, ZiftStatus } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { DemandReportDetailsFilterModel } from "../DetailTable/index.js";
import { isArray } from "lodash";
import { styled } from "styled-components";

class DemandPair implements IDemandPair {
  prints = 0;

  value = 0;

  add(other: IDemandPair): DemandPair {
    const newValue = new DemandPair();
    newValue.prints = this.prints + other.prints;
    newValue.value = this.value + other.value;
    return newValue;
  }
}

interface IDemandPair {
  prints: number;
  value: number;
}

interface DemandAggregate {
  earlier: DemandPair;
  future: DemandPair;
}

interface DemandAggregateEntry extends DemandAggregate {
  dates: Map<string, DemandPair>;
}

interface DemandAggregateRow extends DemandAggregate {
  storeId?: string;
  name: string;
  grandTotal: DemandPair;
  currentTotal: DemandPair;
  dates: Record<string, DemandPair>;
  isRunningTotal: boolean;
}

export interface DemandReportTableProps {
  orderStatus?: OrderStatus[],
  status?: LineItemStatus[],
  ziftStatus?: ZiftStatus[],
  waypoint?: Waypoint[],
  hasSumRow?: boolean,
  hasBin?: boolean
}

// Styles used by the component
// NOTE: Styles must be created outside the component itself
const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

const GridWrapper = styled.div`
    flex: 1 1 auto;
  `;

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

export const DemandReportTable = (props : DemandReportTableProps) => {
  const now = DateTime.now().startOf('day');
  const nowString = now.setLocale("en-US").toLocaleString();

  const defaultStart = now.minus({ days: 2 }).toJSDate();
  const defaultEnd = now.plus({ days: 4 }).toJSDate();

  const [ziftStatus, setZiftStatus] = useQueryParam("ziftStatus", withDefault(ArrayParam, props.ziftStatus ?? []));
  const [displayMode, setDisplayMode] = useQueryParam("displayMode", withDefault(StringParam, 'prints'));
  const [facilityId, setFacilityId] = useQueryParam("facilityId", ArrayParam);
  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateLocalParam, defaultStart),
    to: withDefault(DateLocalParam, defaultEnd)
  });

  const ziftStatuses = useMemo(() => {
    if (isArray(ziftStatus)) {
      return ziftStatus.filter((element): element is string => element !== null) ?? [];
    }
    return [];
  }, [ziftStatus]) as ZiftStatus[];

  const cleanFacilityId = useMemo(() => {
    const validFacilityId = facilityId?.filter((element): element is string => element !== null);
    return validFacilityId?.length ? validFacilityId : undefined;
  }, [facilityId]);

  const updateFilter = (newFilter: any) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
    }));
  };

  const handleDateChange = (dates: any) => {
    updateFilter({
      from: dates?.[0] ? dates[0] : defaultStart,
      to: dates?.[1] ? dates[1] : defaultEnd
    });
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data: stores, isFetching: isFetchingStores } = StoresApi.useGetStoresQuery({});

  const { data: counts, isFetching: isFetchingCounts } = LineItemsApi.useGetLineItemCountsQuery({
    filter: {
      ziftStatus: ziftStatuses,
      status: props.status,
      waypoint: props.waypoint,
      timezone,
      hasBin: props.hasBin,
      orderStatus: props.orderStatus,
      facilityId: cleanFacilityId
    },
    groupBy: "store_id"
  }, {
    refetchOnMountOrArgChange: true
  });

  const pairValueGetter = useCallback((params: ValueGetterParams<DemandAggregateRow>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const value = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    if (!value || (value.prints === 0 && value.value === 0)) {
      return 0;
    }
    if (displayMode === 'units') {
      return value.value as number;
    }
    return value.prints as number;
  }, [displayMode]);

  const pairValueFormatter = useCallback((params: ValueFormatterParams<DemandAggregateRow, number>) => {
    if (!params.value || params.value === 0) {
      return "-";
    }
    return params.value.toLocaleString();
  }, []);

  const displayDates = useMemo(() => {
    const start = DateTime.fromJSDate(filter.from ?? defaultStart);
    const end = DateTime.fromJSDate(filter.to ?? defaultEnd).plus({ days: 1 });
    const length = end.diff(start, 'days').days;
    return Array.from({ length }, (_, i) => start.plus({ days: i }).toJSDate());
  }, [defaultStart, defaultEnd, filter.from, filter.to]);

  const defaultColDef  = useMemo<ColDef<DemandAggregateRow>>(() => ({
    sortable: true,
    width: 80,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'center', fontWeight: 'bold'
    })
  }), []);

  // NOTE: We'd like to use ColDef<DemandAggregateRow>[] here, but there are some dynamic fields
  // that don't match the interface - like all the date columns.
  const columnDefs = useMemo<Array<ColDef<any>>>(() => {
    const defs:Array<ColDef<any>> = [ {
      field: "name",
      headerName: "Accurate Turn Times",
      sort: "asc",
      width: 200,
      cellStyle: (_params: any): any => ({
        textAlign: 'left', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
      })
    }, {
      field: "grandTotal",
      headerName: "Grand Total",
      valueGetter: pairValueGetter,
      valueFormatter: pairValueFormatter
    }, {
      field: "earlier",
      headerName: "Earlier",
      valueGetter: pairValueGetter,
      valueFormatter: pairValueFormatter,
      cellRenderer: EarlierCellRenderer(
        displayDates, cleanFacilityId, props.hasBin, props.status, props.orderStatus, ziftStatuses)
    },
    ...displayDates.map((date) => {
      const dateString = date.toLocaleDateString("en-US", { timeZone: 'utc' });
      return {
        field: `dates.${dateString}`,
        headerName: date.toLocaleDateString("en-US", { timeZone: 'utc', day: 'numeric', month: 'numeric' }),
        valueGetter: pairValueGetter,
        valueFormatter: pairValueFormatter,
        cellRenderer: DatesCellRenderer(date, cleanFacilityId, props.hasBin, props.status, props.orderStatus, ziftStatuses),
        cellStyle: DatesCellStyle(dateString, nowString)
      };
    }
    ),
    {
      field: "future",
      headerName: "Future",
      valueGetter: pairValueGetter,
      valueFormatter: pairValueFormatter,
      cellRenderer: FutureCellRenderer(displayDates, cleanFacilityId, props.hasBin, props.status, props.orderStatus, ziftStatuses)
    }, {
      field: "currentTotal",
      headerName: "Current Total",
      valueGetter: pairValueGetter,
      valueFormatter: pairValueFormatter
    } ];

    return defs;
  }, [displayDates, cleanFacilityId, nowString, props.hasBin, props.status, props.orderStatus, ziftStatuses,
    pairValueFormatter, pairValueGetter]);

  const rowData = useMemo(() => {
    const dataMap = counts?.reduce((accumulator, line) => {
      const rawDate: Date = line.time;
      const stringDate = rawDate.toLocaleDateString("en-US", { timeZone: 'utc' });
      const adjustedDate = new Date(stringDate);
      const storeId = line.data.key === 'store_id' ? line.data.value : undefined;
      if (storeId)
      {
        let entry = accumulator.get(storeId);
        if (!entry) {
          entry = {
            dates: new Map<string, DemandPair>(),
            earlier: new DemandPair(),
            future: new DemandPair()
          };
          accumulator.set(storeId, entry);
        }

        if (adjustedDate < displayDates[0]) {
          entry.earlier = entry.earlier.add(line);
        } else if (adjustedDate > displayDates[displayDates.length - 1]) {
          entry.future = entry.future.add(line);
        } else {
          entry.dates.set(stringDate, (entry.dates.get(stringDate) ?? new DemandPair()).add(line));
        }
      }

      return accumulator;
    }, new Map<string, DemandAggregateEntry>());

    const storeMap = counts?.reduce((accumulator, line) => {
      const storeId = line.data.key === 'store_id' ? line.data.value : undefined;
      const store = stores?.rows.find((s) => s.id === storeId);
      if (store?.name && storeId && !accumulator.has(storeId)) {
        accumulator.set(storeId, store.name);
      }
      return accumulator;
    }, new Map<string, string>());

    return Array.from(dataMap?.keys() ?? []).map((key) => {
      const dateEntry = dataMap?.get(key);
      const currentTotal = Array.from(dateEntry?.dates.values() ?? [])
        .reduce((acc, current) => acc.add(current), new DemandPair());
      const row: DemandAggregateRow = {
        storeId: key,
        name: storeMap?.get(key) ?? key,
        earlier: dateEntry?.earlier ?? new DemandPair(),
        future: dateEntry?.future ?? new DemandPair(),
        grandTotal: currentTotal
          .add(dateEntry?.earlier ?? new DemandPair())
          .add(dateEntry?.future ?? new DemandPair()),
        currentTotal,
        dates: Object.fromEntries(dateEntry?.dates ?? []),
        isRunningTotal: false
      };
      return row;
    });
  }, [counts, stores?.rows, displayDates]);

  const pinnedBottomRowData = useMemo(() => {
    const dateTotals = new Map(
      displayDates.map((date) => [
        date.toLocaleDateString("en-US", { timeZone: 'utc' }),
        rowData.reduce((acc, current) =>
          acc.add(current.dates[date.toLocaleDateString("en-US", { timeZone: 'utc' })] ?? new DemandPair()), new DemandPair())
      ])
    );

    const rows = [];

    const earlierTotal = rowData.reduce((acc, current) => acc.add(current.earlier), new DemandPair());
    const futureTotal = rowData.reduce((acc, current) => acc.add(current.future), new DemandPair());

    const row: DemandAggregateRow = {
      name: "Total",
      earlier: earlierTotal,
      future: futureTotal,
      grandTotal: rowData.reduce((acc, current) => acc.add(current.grandTotal), new DemandPair()),
      currentTotal: rowData.reduce((acc, current) => acc.add(current.currentTotal), new DemandPair()),
      dates: Object.fromEntries(dateTotals),
      isRunningTotal: false
    };
    rows.push(row);

    if (props.hasSumRow) {
      let sum = earlierTotal;
      const sumTotals = displayDates.map((date) => {
        const dateValue = dateTotals.get(date.toLocaleDateString("en-US", { timeZone: 'utc' })) ?? new DemandPair();
        sum = sum.add(dateValue);
        return [
          date.toLocaleDateString("en-US", { timeZone: 'utc' }),
          sum
        ];
      });

      const sumRow: DemandAggregateRow = {
        name: "Running Total",
        earlier: new DemandPair(),
        future: sum.add(futureTotal),
        grandTotal: new DemandPair(),
        currentTotal: new DemandPair(),
        dates: Object.fromEntries(sumTotals),
        isRunningTotal: true
      };
      rows.push(sumRow);
    }
    return rows;
  }, [displayDates, props.hasSumRow, rowData]);

  const gridOptions: GridOptions<DemandAggregateRow> = useMemo(() => ({
    rowData,
    pinnedBottomRowData,
    defaultColDef,
    columnDefs,
    // replace with actual styles later
    getRowStyle(params) {
      if ((params?.node?.rowPinned)) {
        if (params?.node?.rowIndex === 0) {
          return { background: '#71AF47' };
        }
        return { background: '#97C876' };
      }
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      }
      return { background: '#E2EFDA' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [rowData, pinnedBottomRowData, defaultColDef, columnDefs]);

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <Form
            size="small"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="inline"
          >
            <Form.Item label="Facilities" style={{ minWidth: 240 }}>
              <LocationFacilitySelect
                value={cleanFacilityId}
                onChange={(facilityId: string[]) => {
                  setFacilityId(facilityId);
                }}
              />
            </Form.Item>
            <Form.Item label="Display Mode" style={{ minWidth: 240 }}>
              <Select
                key="display-mode"
                placeholder="Select Display Mode"
                value={displayMode}
                onChange={setDisplayMode}
                options={[ { value: 'prints', label: 'Prints' }, { value: 'units', label: 'Units' } ]}
              />
            </Form.Item>
            <Form.Item label="Statuses" style={{ minWidth: 240 }}>
              <Select
                mode="multiple"
                key="zift-status"
                placeholder="Select Statuses"
                value={ziftStatus}
                onChange={setZiftStatus}
                options={[ { value: ZiftStatus.Active, label: 'Active' },
                  { value: ZiftStatus.Pending, label: 'Pending' } ]}
              />
            </Form.Item>
            <Form.Item label="Display Date Range">
              <DateLocalRangePicker
                placeholder={["Start", "End"]}
                allowClear={false}
                allowEmpty={[false, false]}
                value={[filter.from, filter.to] as [any, any]}
                ranges={{
                  "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
                  "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
                }}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={isFetchingCounts || isFetchingStores}>
            <AgGridReact
              className="ag-theme-madengine"
              gridOptions={gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};

function FutureCellRenderer(displayDates: Date[], cleanFacilityId: string[] | undefined,
  hasBin: boolean | undefined, status: LineItemStatus[] | undefined, orderStatuses: OrderStatus[] | undefined,
  ziftStatuses: ZiftStatus[] | undefined) {

  return function FutureCellRendererInternal(c: ICellRendererParams<DemandAggregateRow, number>) {
    if (c.value === undefined || c.value === 0) {
      return '-';
    }

    const date = DateTime.fromJSDate(displayDates.at(-1)!).plus({ days: 1 }).toJSDate();
    const filters: DemandReportDetailsFilterModel = {};

    if (!c?.data?.isRunningTotal) {
      filters['order.shipBy'] = {
        filterType: 'date',
        condition1: {
          type: 'greaterThan',
          dateFrom: date.toISOString()
        },
        condition2: {
          type: 'equals',
          dateFrom: date.toISOString()
        },
        operator: 'OR'
      };
    }

    if (c.node.data?.storeId) {
      filters['store.id'] = {
        filterType: 'set',
        values: [c.node.data?.storeId]
      };
    }

    if (cleanFacilityId) {
      filters['facility.id'] = {
        filterType: 'set',
        values: cleanFacilityId
      };
    }

    if (status) {
      filters.status = {
        filterType: 'set',
        values: status
      };
    }

    if (orderStatuses) {
      filters['order.status'] = {
        filterType: 'set',
        values: orderStatuses
      };
    }

    if (ziftStatuses) {
      filters.ziftStatus = {
        filterType: 'set',
        values: ziftStatuses
      };
    }

    if (hasBin) {
      filters['bin.id'] = {
        filterType: 'text',
        type: 'notBlank'
      };
    }

    return (<Link to="/reports/demand/detail" state={filters}>{c?.valueFormatted}</Link>);
  };
}

function DatesCellStyle(dateString: string, nowString: string): (params: any) => any {
  return (params: any): any => {
    if (dateString === nowString && !params?.node?.rowPinned) {
      return { background: 'rgba(0, 0, 0, .1)', textAlign: 'center', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold' };
    }
    return { textAlign: 'center', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold' };
  };
}

function DatesCellRenderer(date: Date, cleanFacilityId: string[] | undefined,
  hasBin: boolean | undefined, status: LineItemStatus[] | undefined, orderStatuses: OrderStatus[] | undefined,
  ziftStatuses: ZiftStatus[] | undefined) {

  return function DatesCellRendererInternal(c: ICellRendererParams<DemandAggregateRow, number>) {
    if (c.value === undefined || c.value === 0) {
      return '-';
    }

    const filters: DemandReportDetailsFilterModel = {};

    if (!c?.data?.isRunningTotal) {
      const dateTo = DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate();
      filters['order.shipBy'] = {
        filterType: 'date',
        condition1: {
          type: 'inRange',
          dateFrom: date.toISOString(),
          dateTo: dateTo.toISOString(),
        },
        condition2: {
          type: 'notEqual',
          dateFrom: dateTo.toISOString()
        },
        operator: 'AND'
      };
    } else {
      filters['order.shipBy'] = {
        filterType: 'date',
        condition1: {
          type: 'lessThan',
          dateFrom: DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate().toISOString()
        }
      };
    }

    if (c.node.data?.storeId) {
      filters['store.id'] = {
        filterType: 'set',
        values: [c.node.data?.storeId]
      };
    }

    if (cleanFacilityId) {
      filters['facility.id'] = {
        filterType: 'set',
        values: cleanFacilityId
      };
    }

    if (status) {
      filters.status = {
        filterType: 'set',
        values: status
      };
    }

    if (orderStatuses) {
      filters['order.status'] = {
        filterType: 'set',
        values: orderStatuses
      };
    }

    if (ziftStatuses) {
      filters.ziftStatus = {
        filterType: 'set',
        values: ziftStatuses
      };
    }

    if (hasBin) {
      filters['bin.id'] = {
        filterType: 'text',
        type: 'notBlank'
      };
    }

    return (<Link to="/reports/demand/detail" state={filters}>{c?.valueFormatted}</Link>);
  };
}

function EarlierCellRenderer(displayDates: Date[], cleanFacilityId: string[] | undefined,
  hasBin: boolean | undefined, status: LineItemStatus[] | undefined, orderStatuses: OrderStatus[] | undefined,
  ziftStatuses: ZiftStatus[] | undefined) {

  return function EarlierCellRendererInternal(c: ICellRendererParams<DemandAggregateRow, number>) {
    if (c.value === undefined || c.value === 0) {
      return '-';
    }

    const date = DateTime.fromJSDate(displayDates[0]).toJSDate();
    const filters: DemandReportDetailsFilterModel = {};

    filters['order.shipBy'] = {
      filterType: 'date',
      condition1: {
        type: 'lessThan',
        dateFrom: date.toISOString()
      }
    };

    if (c.node.data?.storeId) {
      filters['store.id'] = {
        filterType: 'set',
        values: [c.node.data?.storeId]
      };
    }

    if (cleanFacilityId) {
      filters['facility.id'] = {
        filterType: 'set',
        values: cleanFacilityId
      };
    }

    if (status) {
      filters.status = {
        filterType: 'set',
        values: status
      };
    }

    if (orderStatuses) {
      filters['order.status'] = {
        filterType: 'set',
        values: orderStatuses
      };
    }

    if (ziftStatuses) {
      filters.ziftStatus = {
        filterType: 'set',
        values: ziftStatuses
      };
    }

    if (hasBin) {
      filters['bin.id'] = {
        filterType: 'text',
        type: 'notBlank'
      };
    }

    return (<Link to="/reports/demand/detail" state={filters}>{c?.valueFormatted}</Link>);
  };
}
