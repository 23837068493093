import React, { useMemo } from "react";
import { ColDef, ColGroupDef, GetRowIdParams, GridOptions, IToolPanelColumnCompParams, SideBarDef } from "ag-grid-enterprise";
import { DataGrid, DataGridProps } from "@fifthsun/ui/ag-grid";
import { Batch } from "../../../api/core/index.js";
import { useBatchesDatasource, BatchesDatasourceProps, DefaultBatchesDatasourceProps } from "./useBatchesDatasource.js";
import { createBatchColDefs, CreateBatchColDefsProps } from "./createBatchColDefs.js";
import { BatchSchedulingToolPanel, BatchSchedulingToolPanelProps } from "./components/toolPanels/BatchSchedulingToolPanel.js";
import { BulkEditNotesToolPanel } from "./components/toolPanels/BulkEditNotesToolPanel.js";
import { BatchProgressCellRenderer, StoresCellRenderer } from "./renderers/index.js";
import * as renderers from "@fifthsun/ui/ag-grid/renderers";

export interface BatchesDataGridProps extends DataGridProps {
  /** customize the props to use for the BatchesDatasource */
  serverSideDatasourceProps?: BatchesDatasourceProps;

  /** If no colDefs are provided, this is required in order to create the default set of colDefs */
  columnDefsParams?: CreateBatchColDefsProps;

  /** Parameters for the BatchSchedulingToolPanel. Ignored if a sideBar is supplied. */
  schedulingToolPanelParams?: BatchSchedulingToolPanelProps;
}

export const BatchesDataGrid = (props: BatchesDataGridProps) => {
  const { 
    plugins, 
    sideBar, 
    columnDefsParams,
    schedulingToolPanelParams, 
    serverSideDatasourceProps, 
    ...gridProps 
  } = props;

  const dsp: BatchesDatasourceProps = useMemo<BatchesDatasourceProps>(() => {
    return serverSideDatasourceProps ?? DefaultBatchesDatasourceProps;
  }, [serverSideDatasourceProps]);

  const serverSideDatasource = useBatchesDatasource(dsp);

  const columnDefs = useMemo<Array<ColDef<Batch> | ColGroupDef<Batch>>>(() => {
    if (props.columnDefs) { return props.columnDefs; }
    if (!columnDefsParams) { throw new Error("Either `columnDefs` or `columnDefsParams` are required"); }
    return createBatchColDefs(columnDefsParams);
  }, [props.columnDefs, columnDefsParams]);

  const defaultGridOptions:GridOptions = useMemo(() => {
    return {
      rowModelType: 'serverSide',
      getRowId: (param: GetRowIdParams) => (param.data as Batch).id,
      components: {
        ...Object.values(renderers),
        BatchProgressCellRenderer,
        StoresCellRenderer
      },
    };
  }, []);

  const activeServerSideDatasource = useMemo(() => {
    if (!gridProps.rowModelType || gridProps.rowModelType === 'serverSide') {
      return gridProps.serverSideDatasource ?? serverSideDatasource;
    }
    return undefined;
  }, [gridProps.rowModelType, gridProps.serverSideDatasource, serverSideDatasource]);

  const memoizedSideBar = useMemo<SideBarDef | string[] | string | boolean | null | undefined>(() => {
    return sideBar ?? {
      toolPanels: [
        {
          id: 'columns',
          toolPanel: 'agColumnsToolPanel',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressValues: true,
            suppressRowGroups: true
          } as IToolPanelColumnCompParams,
        }, 
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: true,
            suppressFilterSearch: true,
          },
        }, 
        {
          id: 'batchScheduling',
          labelDefault: 'Scheduling',
          labelKey: 'batchScheduling',
          iconKey: 'columns',
          toolPanel: BatchSchedulingToolPanel,
          toolPanelParams: schedulingToolPanelParams,
          width: 480
        },
        {
          id: 'bulkEditNotes',
          labelDefault: 'Edit Notes',
          labelKey: 'bulkEditNotes',
          iconKey: 'paste',
          toolPanel: BulkEditNotesToolPanel,
        }
      ] 
    };
  }, [sideBar, schedulingToolPanelParams]);

  return (
    <DataGrid
      {...defaultGridOptions}
      {...gridProps}
      columnDefs={columnDefs}
      sideBar={memoizedSideBar}
      serverSideDatasource={activeServerSideDatasource}
      plugins={plugins}
    />
  );
};

export default BatchesDataGrid;
