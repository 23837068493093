import { useMemo } from "react";
import { 
  AgGridDatasourceProps, AgGridQueryArgsFunc, BatchesApi, GetBatches, GetBatchesVariables, 
  GetBatchesVariables_Options, useAgGridDatasource 
} from "../../../api/core/index.js";

export interface BatchesDatasourceProps 
  extends Omit<AgGridDatasourceProps<GetBatchesVariables_Options, GetBatchesVariables, GetBatches>, "endpoint"> {}

export const DefaultBatchesDatasourceQueryArgsFunc: AgGridQueryArgsFunc = (args) => {
  // The "stores" column data type is Store. We display the name, but the filter value is the id.
  // NOTE: we do not currently permit sorting by this column; that would probably require an update
  // to Core, and we'd want to sort by the name rather than the id.
  if (args.filter?.stores) {
    args.filter["stores.id"] = args.filter.stores;
    delete args.filter.stores;
  }

  const replace_key = "schedule.facility.";
  const schedule_facility_filters = Object.keys(args.filter ?? {})
    .filter((key) => key.startsWith(replace_key));

  schedule_facility_filters.forEach((key) => {
    const new_key = `schedule_facility.${key.substring(replace_key.length)}`;
    if (args.filter![new_key]) { 
      throw new Error(`Cannot update "${key}" to "${new_key}": there is already an entry under "${new_key}"`);
    }
    args.filter![new_key] = args.filter![key];
    delete args.filter![key];
  });

  const ndx = args.sort?.findIndex((s) => s.colId.startsWith("schedule.facility.")) ?? -1;
  if (ndx >= 0) {
    const s = args.sort![ndx];
    s.colId = s.colId.replace("schedule.facility.", "schedule_facility.");
  }

  return args;
};

export const DefaultBatchesDatasourceProps = Object.freeze<BatchesDatasourceProps>({
  queryArgs: DefaultBatchesDatasourceQueryArgsFunc,
  options: {
    count: true,
    include: ["stores"],
  },
});

export function useBatchesDatasource(props: BatchesDatasourceProps = DefaultBatchesDatasourceProps) {
  const datasourceProps = useMemo<AgGridDatasourceProps<GetBatchesVariables_Options>>(() => ({
    ...DefaultBatchesDatasourceProps,
    ...props,
    options: {
      ...DefaultBatchesDatasourceProps.options,
      ...props.options,
    },
    endpoint: BatchesApi.endpoints.getBatches,
  }), [props]);

  return useAgGridDatasource<GetBatchesVariables_Options>(datasourceProps);
};
