import React, { useMemo } from "react";
import { LineItemDataGrid, LineItemDataGridProps } from "../../LineItem/index.js";
import { ColDef, FirstDataRenderedEvent, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";

import { 
  AgDateFilter, AgNumberFilterCondition, AgSetFilterCondition, AgTextFilterCondition, 
  BatchStatus, LineItem, LineItemStatus 
} from "../../../api/core/index.js";

import { IDataGridPlugin, useSetFilterModelPlugin, useSetFilterModelPluginParams } from "@fifthsun/ui/ag-grid/plugins";
import { FacilityFilter, PrinterFilter, LocalDateFilter, StoreFilter } from "../../DataGrid/filters/index.js";
import { formatTwoDigitDate } from "@fifthsun/ui/utils";

interface PendingPrintFilterModel {
  'order.shipBy'?: AgDateFilter,
  'store.id'?: AgSetFilterCondition,
  'facility.id'?: AgSetFilterCondition,
  'order.status'?: AgSetFilterCondition,
  'status'?: AgSetFilterCondition,
  'bin.id'?: AgTextFilterCondition,
  'updatedAt'?: AgDateFilter,
  'progress'?: AgNumberFilterCondition,
  'batch.status'?: AgSetFilterCondition,
  'printer_model'?: AgSetFilterCondition
}

const lineItemStatus = [
  LineItemStatus.Branded, LineItemStatus.Branding, LineItemStatus.New, LineItemStatus.Packaged, LineItemStatus.Packaging, 
  LineItemStatus.Printed, LineItemStatus.Printing, LineItemStatus.Pulled, LineItemStatus.Shipping, LineItemStatus.OutOfStock,
  LineItemStatus.Defective
];

const batchStatus = [
  BatchStatus.BrandingComplete, BatchStatus.Complete, BatchStatus.DeliveredToPrinter, BatchStatus.InProgress, 
  BatchStatus.New, BatchStatus.Pulled
];

export const PendingPrintTable = () => {

  const filterModel = useMemo<PendingPrintFilterModel>(() => ({
    progress: {
      type: 'lessThan',
      filter: 56
    },
    status: {
      values: lineItemStatus,
    },
    "batch.status": {
      values: batchStatus
    }
  }), []);

  const setFilterModelPluginProps = useMemo<useSetFilterModelPluginParams>(() => ({ filterModel }), [filterModel]);
  const setFilterModelPlugin = useSetFilterModelPlugin(setFilterModelPluginProps);
  const plugins = useMemo<IDataGridPlugin[]>(() => [setFilterModelPlugin], [setFilterModelPlugin]);

  const dateValueGetter = (params : ValueGetterParams<LineItem>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const date = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return date ? formatTwoDigitDate(date, params.data?.facility?.timezone ?? 'UTC') : null;
  };

  const defaultColDef: ColDef<LineItem> = useMemo(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'left', fontWeight: 'bold'
    })
  }), []);

  const columnDefs = useMemo<Array<ColDef<any>>>(() => {
    return [ {
      field: 'store.name',
      headerName: 'Customer Name',
      width: 200
    }, {
      field: 'facility.name',
      headerName: 'Facility',
      width: 200
    }, {
      field: 'batch.ziftId',
      headerName: 'Batch ID',
      width: 250,
      sort: 'asc',
      sortIndex: 0
    }, {
      field: 'order.ziftId',
      headerName: 'Order ID',
      width: 250,
      sort: 'asc',
      sortIndex: 1
    }, {
      field: 'ziftId',
      headerName: 'Order Product ID',
      width: 250,
      sort: 'asc',
      sortIndex: 2
    }, {
      field: 'order.shipBy',
      headerName: 'Ship By',
      valueGetter: dateValueGetter,
      width: 125,
      cellClass: 'dateFormat',
      filter: LocalDateFilter,
      filterParams: {
        timezoneField: 'facility.timezone'
      }
    }, {
      headerName: 'Units',
      valueGetter: '1', // OP is always one unit
      width: 250
    }, {
      field: 'printsCount',
      headerName: 'Prints',
      width: 250
    }, {
      field: 'printerModel',
      headerName: 'Printer Type',
      width: 250
    }, {
      field: 'quantity',
      headerName: 'Units in Order',
      width: 250
    }, {
      field: 'order.printerModelCounts',
      headerName: 'Prints in Order',
      width: 150,
      sortable: false,
      valueGetter: (params: ValueGetterParams<LineItem>) => {
        const key = params.data?.printerModel;
        if (key) {
          return params.data?.order?.printerModelCounts?.[key.toLocaleLowerCase()];
        }
        return '';
      }
    }, {
      field: 'waypoint',
      headerName: 'Last Waypoint',
      width: 150,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // convert oos to human readable
        if (params.value.toLocaleUpperCase() === 'OOS') {
          return 'Out of Stock';
        }
        // enum case to human readable
        return params.value
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
          .join(" ");
      }
    }, {
      field: 'progress',
      headerName: 'Progress',
      hide: true,
      filter: 'agNumberColumnFilter',
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true
    }, {
      field: 'status',
      headerName: 'Status',
      hide: true,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // enum case to human readable
        return params.value
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
          .join(" ");
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(LineItemStatus).map((v) => v.toLocaleLowerCase()),
      },
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true
    }, {
      field: 'facility.id',
      headerName: 'Facility',
      hide: true,
      filter: FacilityFilter,
      suppressColumnsToolPanel: true
    }, {
      field: 'store.id',
      headerName: 'Customer Name',
      hide: true,
      filter: StoreFilter,
      suppressColumnsToolPanel: true
    }, {
      field: 'batch.status',
      headerName: 'Batch Status',
      hide: true,
      valueFormatter: (params: ValueFormatterParams<LineItem, string | null>) => {
        if (!params.value) {
          return '';
        }
        // enum case to human readable
        return params.value
          .split('_')
          .map((word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase())
          .join(" ");
      },
      filter: 'agSetColumnFilter',
      filterParams: {
        values: Object.values(BatchStatus).map((v) => v.toLocaleLowerCase()),
      },
      suppressFiltersToolPanel: true,
      suppressColumnsToolPanel: true
    }, {
      field: 'printer.id',
      headerName: 'Printer Type',
      hide: true,
      filter: PrinterFilter,
      suppressColumnsToolPanel: true
    } ];
  }, []);

  const gridProps = useMemo<Partial<LineItemDataGridProps>>(() => ({
    include: ['order', 'store', 'facility', 'batch'],
    defaultColDef,
    columnDefs,
    getRowStyle: (params) => {
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      } 
      return { background: '#E2EFDA' }; 
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
    rowSelection: {
      mode: "multiRow",
      checkboxes: false,
      headerCheckbox: false,
      enableClickSelection: false,
    },
  }), [columnDefs, defaultColDef]);

  return (
    <>
      <LineItemDataGrid
        {...gridProps}
        plugins={plugins}
      />
    </>
  );
};
