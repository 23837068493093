import React, { useMemo } from "react";
import { ColDef, GridOptions } from "ag-grid-enterprise";
import { FirstDataRenderedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { LocationFacilitySelect } from "../../Facility/index.js";
import { ArrayParam, useQueryParam } from "@fifthsun/ui/api/queryParams";
import { Form, Spin } from "antd";
import { LineItemsApi, OrderStatus } from "../../../api/core/index.js";
import { styled } from "styled-components";

interface WaypointAggregateRow {
  waypoint: string;
  value: number;
  flag: string;
  rank: number;
}

interface WaypointData {
  rank: number,
  flag: string
}

// this is not stored anywhere and is only used in visualizing the report
const waypointMetadata = new Map<string, WaypointData>([
  ["none", { rank: 0, flag: 'Unbatched' } ],
  ["batch", { rank: 1, flag: 'Batched' } ],
  ["oos", { rank: 2, flag: 'Out of Stock' } ],
  ["pull", { rank: 3, flag: 'Batched' } ],
  ["branding", { rank: 4, flag: 'Batched' } ],
  ["print_back", { rank: 5, flag: 'Printed' } ],
  ["print_front", { rank: 6, flag: 'Printed' } ],
  ["print_neckhit", { rank: 7, flag: 'Printed' } ],
  ["print_rsleeve", { rank: 8, flag: 'Printed' } ],
  ["print_wraparound", { rank: 9, flag: 'Printed' } ],
  ["dry_back", { rank: 10, flag: 'Printed' } ],
  ["dry_front", { rank: 11, flag: 'Printed' } ],
  ["dry_highback", { rank: 12, flag: 'Printed' } ],
  ["dry_lsleeve", { rank: 13, flag: 'Printed' } ],
  ["dry_neckhit", { rank: 14, flag: 'Printed' } ],
  ["dry_rsleeve", { rank: 15, flag: 'Printed' } ],
  ["dry_wraparound", { rank: 16, flag: 'Printed' } ],
  ["hold_unit", { rank: 17, flag: 'Batched' } ],
  ["qc_issue", { rank: 18, flag: 'Batched' } ],
  ["reprocess", { rank: 19, flag: 'Batched' } ],
  ["set_box", { rank: 20, flag: 'Printed' } ],
  ["bin", { rank: 21, flag: 'Printed' } ],
  ["package", { rank: 22, flag: 'Printed' } ],
  ["postage", { rank: 23, flag: 'Printed' } ],
  ["receive", { rank: 24, flag: 'Printed' } ],
  ["ship_confirm", { rank: 25, flag: 'Printed' } ]
]);

// Styles used by the component
// NOTE: Styles must be created outside the component itself
const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

const GridWrapper = styled.div`
    flex: 1 1 auto;
  `;

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

export const WaypointDemandReportTable = () => {
  const [facilityId, setFacilityId] = useQueryParam("facilityId", ArrayParam);

  const cleanFacilityId = useMemo(() => {
    const validFacilityId = facilityId?.filter((element): element is string => element !== null);
    return validFacilityId?.length ? validFacilityId : undefined;
  }, [facilityId]);

  const numberValueFormatter = ({ value }: any) => {
    const numberValue = (value || 0);
    if (numberValue === 0) {
      return "-";
    }
    return numberValue.toLocaleString();
  };

  const { data, isFetching: loading } = LineItemsApi.useGetWaypointsQuery({
    orderStatus: [OrderStatus.Active, OrderStatus.Pending],
    facilityId: cleanFacilityId
  }, {
    refetchOnMountOrArgChange: true
  });

  const defaultColDef = useMemo<ColDef<WaypointAggregateRow>>(() => ({
    sortable: true,
    width: 120,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      textAlign: 'left', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
    })
  }), []);

  const columnDefs: Array<ColDef<WaypointAggregateRow>> = useMemo(() => {
    return [ {
      field: "rank",
      headerName: "Rank",
      sort: 'asc',
      hide: true
    }, {
      field: "waypoint",
      headerName: "Last WP",
      width: 200
    }, {
      field: "value",
      headerName: "Units",
      valueFormatter: numberValueFormatter,
      cellStyle: (_params: any): any => ({
        textAlign: 'center', border: '1px solid rgba(0, 0, 0, .1)', fontWeight: 'bold'
      })
    }, {
      field: "flag",
      headerName: "Flag"
    } ];
  }, []);

  const rowData = useMemo(() => {
    const rows = data?.map<WaypointAggregateRow>((row) =>
      ({
        waypoint: row.waypoint,
        value: row.value,
        flag: waypointMetadata.get(row.waypoint)?.flag ?? 'Unknown',
        rank: waypointMetadata.get(row.waypoint)?.rank ?? Number.MAX_SAFE_INTEGER
      })
    );
    return rows ?? [];
  }, [data]);

  const pinnedBottomRowData = useMemo(() => {
    const total = rowData.reduce((accumulator, line) => {
      return accumulator + line.value;
    }, 0);

    return [ {
      waypoint: 'Total',
      value: total,
      flag: '',
      rank: Number.MAX_SAFE_INTEGER
    } ];
  }, [rowData]);

  const gridOptions: GridOptions<WaypointAggregateRow> = useMemo(() => ({
    rowData,
    defaultColDef,
    columnDefs,
    pinnedBottomRowData,
    // replace with actual styles later
    getRowStyle(params) {
      if ((params?.node?.rowPinned)) {
        return { background: '#8EA9DB' };
      }
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#B4C6E7' };
      }
      return { background: '#D9E1F2' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [columnDefs, defaultColDef, pinnedBottomRowData, rowData]);

  return (
    <>
      <Wrapper>
        <FilterWrapper className="ag-theme-madengine">
          <Form
            size="small"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            layout="inline"
          >
            <Form.Item label="Facilities" style={{ minWidth: 240 }}>
              <LocationFacilitySelect
                value={cleanFacilityId}
                onChange={(facilityId: string[]) => {
                  setFacilityId(facilityId);
                }}
              />
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={loading}>
            <AgGridReact
              className="ag-theme-madengine"
              gridOptions={gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};
