 
import React, { useMemo } from "react";
import { DataGrid } from "@fifthsun/ui/ag-grid";
import { Button, Drawer } from "antd";
import { BatchDetail, BatchesDataGrid, BatchesDataGridProps, BatchesDatasourceProps, DefaultBatchesDatasourceProps } from "../index.js";
import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { Batch, GetBatchesVariables_Includes } from "../../../api/core/Batches/index.js";
import { FacilitiesApi, Facility, Store, StoresApi } from "../../../api/core/index.js";
import { ErrorBoundary } from "@sentry/react";
import ErrorBoundaryFallback from "../../Page/ErrorBoundaryFallback.js";
import { useAuthentication } from "@fifthsun/ui";
import { createBatchColDefs, CreateBatchColDefsProps } from "./createBatchColDefs.js";
import { BatchSchedulingToolPanelProps } from "./components/toolPanels/BatchSchedulingToolPanel.js";

export interface BatchTableProps extends BatchesDataGridProps {
  activeBatchId?: string | null;
  setActiveBatchId?: (batchId: string | null | undefined) => void;
  hideSchedule?: boolean;
  hideUnschedule?: boolean;
}

export interface BatchTableDataGridProps extends BatchTableProps {
  facilities: Facility[];
  stores: Store[];
}

const BatchTableDatasourceIncludes:GetBatchesVariables_Includes[] = [
  "auto_facility", "note", "schedule", "schedule_facility", "stores", "line_item_stats", "store_stats"
];

const BatchTableDataGrid = (props: BatchTableDataGridProps) => {
  const { 
    facilities, stores, 
    hideSchedule, hideUnschedule, 
    activeBatchId, setActiveBatchId, 
    columnDefs, columnDefsParams, 
    serverSideDatasource, serverSideDatasourceProps, 
    sideBar, schedulingToolPanelParams, 
    ...gridProps 
  } = props;  

  const colDefs = useMemo(() => {
    const params: CreateBatchColDefsProps = columnDefsParams ?? {
      facilities,
      stores, 
      // if we are hiding schedule, we are on the scheduler and want to sort by compound priority
      includeCompoundPriorityInSort: hideSchedule ?? false,
      // additionally, if we are on the scheduler then we want to show and default sort by the schedulePinned field
      includeSchedulePinnedField: hideSchedule ?? false
    };

    const cols = columnDefs ?? createBatchColDefs(params);

    const actionsColDef: ColDef<Batch> = {
      field: undefined,
      headerName: "Actions",
      cellRenderer: ActionsColumnCellRenderer(setActiveBatchId),
      filter: false,
      sortable: false,
    };

    cols.push(actionsColDef); 
    return cols;
  }, [columnDefs, columnDefsParams, facilities, stores, setActiveBatchId, hideSchedule]);

  const activeSchedulingTpProps = useMemo<BatchSchedulingToolPanelProps>(() => {
    return schedulingToolPanelParams ?? {
      hideSchedule,
      hideUnschedule
    };
  }, [schedulingToolPanelParams, hideSchedule, hideUnschedule]);

  const dsp = useMemo<BatchesDatasourceProps>(() => {
    return serverSideDatasourceProps ?? {
      ...DefaultBatchesDatasourceProps,
      options: {
        ...DefaultBatchesDatasourceProps.options,
        include: BatchTableDatasourceIncludes
      },
    };
  }, [serverSideDatasourceProps]);

  return (
    <BatchesDataGrid
      {...gridProps} 
      columnDefs={colDefs}
      serverSideDatasource={serverSideDatasource}
      serverSideDatasourceProps={dsp}
      schedulingToolPanelParams={activeSchedulingTpProps}
      sideBar={sideBar}
    />
  );
};

const BatchDetails = (props: BatchTableProps) => {
  const { activeBatchId, setActiveBatchId } = props;
  return (
    <Drawer
      open={!!activeBatchId}
      width="60vw"
      onClose={() => setActiveBatchId?.(undefined)}
    >
      {activeBatchId ? <BatchDetail id={activeBatchId} /> : undefined}
    </Drawer>
  );
};

export interface LoadErrorProps {
  message: string;
  retry: () => void;
}

export const LoadError = (props: LoadErrorProps) => {
  return (
    <div>
      <b>{props.message}</b>
      <br />
      <Button onClick={props.retry}>Retry</Button>
    </div>
  );
};

export const BatchTable = (props : BatchTableProps) => {
  const { authenticated } = useAuthentication();
  const { 
    data: facilityData, 
    refetch: facilityRefetch, 
    isSuccess: facilityIsSuccess,
    isError: facilityIsError, 
    isLoading: facilityIsLoading, 
    isUninitialized: facilityIsUninitialized 
  } = FacilitiesApi.useGetFacilitiesQuery({ limit: 0 }, { skip: !authenticated });

  const { 
    data: storeData, 
    refetch: storeRefetch, 
    isSuccess: storeIsSuccess,
    isError: storeIsError,
    isLoading: storeIsLoading, 
    isUninitialized: storeIsUninitialized
  } = StoresApi.useGetStoresQuery({ limit: 0 }, { skip: !authenticated });

  if (!authenticated) {
    return null; // an overlay is displayed as part of the route configuration
  }

  if (facilityIsUninitialized || facilityIsLoading || !facilityIsSuccess || storeIsUninitialized || storeIsLoading || !storeIsSuccess) {
    return (
      <DataGrid
        overlayLoadingTemplate={"Loading Facilities & Stores..."}
      />
    );
  }

  if (facilityIsError) {
    return (
      <DataGrid
        loadingOverlayComponent={LoadError}
        loadingOverlayComponentParams={{
          message: "Failed to retrieve the list of Facilities",
          retry: facilityRefetch,
        }}
      />
    );
  }

  if (storeIsError) {
    return (
      <DataGrid
        loadingOverlayComponent={LoadError}
        loadingOverlayComponentParams={{
          message: "Failed to retrieve the list of Stores",
          retry: storeRefetch,
        }}
      />
    );
  }

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback}>
      <BatchTableDataGrid {...props} facilities={facilityData.rows} stores={storeData.rows} />
      <BatchDetails {...props} />
    </ErrorBoundary>
  );
};

function ActionsColumnCellRenderer(setActiveBatchId: ((batchId: string | null | undefined) => void) | undefined): any {
  return function ActionsColumnCellRendererInternal(params: ICellRendererParams) {
    return (
      <>
        <Button size="small" onClick={() => setActiveBatchId?.(params.data.id)} key="actions-batch-details">
          Details
        </Button>
      </>
    );
  };
}
