import React, { useMemo } from "react";
import { ColDef, FirstDataRenderedEvent, GridOptions, ValueGetterParams } from "ag-grid-community";
import { Form, Spin } from "antd";
import { AgGridReact } from "ag-grid-react";
import { formatTwoDigitDate } from "@fifthsun/ui/utils";
import { ArrayParam, DateLocalParam, useQueryParam, useQueryParams, withDefault } from "@fifthsun/ui/api/queryParams";
import { DateLocalRangePicker } from "../../DateLocalRangePicker/index.js";
import { LocationFacilitySelect } from "../../Facility/index.js";
import { BlankStats, BlanksApi } from "../../../api/core/index.js";
import { DateTime } from "luxon";
import { styled } from "styled-components";

// Styles used by the component
// NOTE: Styles must be created outside the component itself
const FilterWrapper = styled.div`
    flex: 0 1 auto;
  `;

const GridWrapper = styled.div`
    flex: 1 1 auto;
  `;

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
  `;

export const BlankDemandReportTable = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const now = DateTime.now().startOf('day').toJSDate();

  const defaultStart = DateTime.fromJSDate(now).minus({ days: 7 }).toJSDate();
  const defaultEnd = now;

  const [facilityId, setFacilityId] = useQueryParam("facilityId", ArrayParam);
  const [filter, setFilter] = useQueryParams({
    from: withDefault(DateLocalParam, defaultStart),
    to: withDefault(DateLocalParam, defaultEnd)
  });

  const cleanFacilityId = useMemo(() => {
    const validFacilityId = facilityId?.filter((element): element is string => element !== null);
    return validFacilityId?.length ? validFacilityId : undefined;
  }, [facilityId]);

  const updateFilter = (newFilter: any) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
    }));
  };

  const { isFetching, data } = BlanksApi.useGetBlankStatsQuery({
    lineItemFrom: filter.from ?? defaultStart,
    lineItemTo: DateTime.fromJSDate(filter.to ?? defaultEnd).plus({ days: 1 }).toJSDate(), // add one day to end so that we query less than first moment of the next day
    facilityId: cleanFacilityId,
    timezone
  });

  const dateValueGetter = (params : ValueGetterParams<BlankStats>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const date = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);
    return date ? formatTwoDigitDate(date) : null;
  };

  const defaultColDef = useMemo<ColDef<BlankStats>>(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'left', fontWeight: 'bold'
    })
  }), []);

  const columnDefs: Array<ColDef<BlankStats>> = useMemo(() => {
    return [ {
      field: 'fingerprint',
      headerName: 'Fingerprint',
      width: 200
    }, {
      field: 'facilityName',
      headerName: 'Facility',
      width: 200
    }, {
      field: 'date',
      headerName: 'Date',
      width: 200,
      valueGetter: dateValueGetter
    }, {
      field: 'count',
      headerName: 'Count',
      width: 200
    } ];
  }, []);

  const rowData = useMemo(() => {
    const rows = data?.map<BlankStats>((row) => row);
    return rows ?? [];
  }, [data]);

  const gridOptions: GridOptions<BlankStats> = useMemo(() => ({
    rowData,
    defaultColDef,
    columnDefs,
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left',
        }
      ]
    },
    getRowStyle: (params) => {
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      }
      return { background: '#E2EFDA' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    },
  }), [columnDefs, defaultColDef, rowData]);

  return (
    <>
      <Wrapper>
        <FilterWrapper>
          <Form
            size="small"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            layout="inline"
          >
            <Form.Item label="Facilities" style={{ minWidth: 240 }}>
              <LocationFacilitySelect
                value={cleanFacilityId}
                onChange={(facilityId: string[]) => {
                  setFacilityId(facilityId);
                }}
              />
            </Form.Item>
            <Form.Item label="Date Range">
              <DateLocalRangePicker
                placeholder={["Start", "End"]}
                allowClear={false}
                allowEmpty={[false, false]}
                value={[filter.from, filter.to] as [Date, Date]}
                ranges={{
                  "This Week": [DateTime.now().startOf('week').toJSDate(), DateTime.now().endOf('week').toJSDate()],
                  "This Month": [DateTime.now().startOf('month').toJSDate(), DateTime.now().endOf('month').toJSDate()],
                }}
                onChange={(values) => {
                  updateFilter({
                    from: values?.[0] ? values[0] : defaultStart,
                    to: values?.[1] ? values[1] : defaultEnd
                  });
                }}
              />
            </Form.Item>
          </Form>
        </FilterWrapper>
        <GridWrapper>
          <Spin spinning={isFetching}>
            <AgGridReact
              className="ag-theme-madengine"
              gridOptions={gridOptions}
            />
          </Spin>
        </GridWrapper>
      </Wrapper>
    </>
  );
};
