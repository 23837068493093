import React, { useMemo } from "react";
import { AgDateFilter, AgSetFilterCondition, QuotumUsage } from "../../../api/core/index.js";
import { ColDef, FirstDataRenderedEvent, ValueGetterParams } from "ag-grid-community";
import { QuotumUsageDataGrid, QuotumUsageDataGridProps } from "../../QuotumUsage/index.js";
import { useLocation } from "react-router-dom";
import { useSetFilterModelPlugin } from "@fifthsun/ui/ag-grid/plugins";
import { formatTwoDigitDate } from "@fifthsun/ui/utils";
import { StoreFilter } from "../../DataGrid/filters/index.js";
import { LocaleNumberCellRenderer } from "@fifthsun/ui/ag-grid/renderers";

export interface QuotumUsageReportFilterModel {
  'date'?: AgDateFilter,
  'store.id'?: AgSetFilterCondition
}

export interface QuotumUsageReportReportTableProps {
  filterModel?: QuotumUsageReportFilterModel
}

export const AllocationReportTable = (props : QuotumUsageReportReportTableProps) => {
  const state = useLocation().state as QuotumUsageReportFilterModel;
  const filterModel: QuotumUsageReportFilterModel = { ...state, ...props.filterModel };
  const setFilterModelPlugin = useSetFilterModelPlugin({ filterModel });

  const dateValueGetter = (params : ValueGetterParams<QuotumUsage>) => {
    const paths = params.colDef.field?.split('.') ?? [];
    // definitely an abuse of "any", but necessary to drill down arbitrarily
    const date = paths.reduce((prev, curr) => {
      if (prev) {
        return (prev as any)[curr];
      }
      return undefined;
    }, params.data as any);

    return date ? formatTwoDigitDate(date, 'UTC') : null;
  };

  const defaultColDef  = useMemo<ColDef<QuotumUsage>>(() => ({
    sortable: true,
    enableCellChangeFlash: true,
    cellStyle: (_params: any): any => ({
      border: '1px solid rgba(0, 0, 0, .1)', textAlign: 'left', fontWeight: 'bold'
    })
  }), []);

  const columnDefs: Array<ColDef<QuotumUsage>> = useMemo(() => {
    return [ {
      field: 'store.name',
      headerName: 'Customer',
      width: 250,
      sort: 'asc'
    }, {
      field: 'date',
      headerName: 'Date',
      filter: 'agDateColumnFilter',
      width: 200,
      sort: 'asc',
      valueGetter: dateValueGetter
    }, {
      headerName: 'Day of Week',
      valueGetter: (params) => {
        return params.data?.date.toLocaleString('en-us', { timeZone: 'utc', weekday: 'long' });
      },
      width: 200,
      sortable: false
    }, {
      field: 'quotum.value',
      headerName: 'Allocation',
      width: 200,
      cellRenderer: LocaleNumberCellRenderer
    }, {
      field: 'scheduled',
      headerName: 'Scheduled',
      width: 200,
      cellRenderer: LocaleNumberCellRenderer
    }, {
      field: 'printed',
      headerName: 'Printed',
      width: 200,
      cellRenderer: LocaleNumberCellRenderer
    }, {
      field: 'shipped',
      headerName: 'Shipped',
      width: 200,
      cellRenderer: LocaleNumberCellRenderer
    }, {
      headerName: 'Remaining to Print',
      valueGetter: 'getValue("scheduled")',
      width: 200,
      sortable: false,
      cellRenderer: LocaleNumberCellRenderer,
    }, {
      headerName: 'Remaining to Ship',
      valueGetter: 'getValue("printed") + getValue("scheduled")',
      width: 200,
      sortable: false,
      cellRenderer: LocaleNumberCellRenderer,
    }, {
      headerName: 'Total',
      valueGetter: 'getValue("shipped") + getValue("printed") + getValue("scheduled")',
      width: 200,
      sortable: false,
      cellRenderer: LocaleNumberCellRenderer,
      cellStyle: (params: any): any => {
        const isOverAllocation = params?.value > params?.node?.data?.quotum?.value;
        return {
          background: isOverAllocation ? 'rgba(255, 0, 0, .2)' : 'rgba(0, 0, 0, 0)',
          border: '1px solid rgba(0, 0, 0, .1)',
          fontWeight: 'bold'
        };
      }
    }, {
      field: 'store.id',
      headerName: 'Store',
      hide: true,
      filter: StoreFilter,
    } ];
  }, []);

  const gridProps = useMemo<Partial<QuotumUsageDataGridProps>>(() => ({
    defaultColDef,
    columnDefs,
    getRowStyle: (params) => {
      if ((params?.node?.rowIndex ?? 0) % 2 === 0) {
        return { background: '#C6E0B4' };
      }
      return { background: '#E2EFDA' };
    },
    animateRows: true,
    onFirstDataRendered: (e: FirstDataRenderedEvent) => {
      e.api.autoSizeAllColumns();
    }
  }), [columnDefs, defaultColDef]);

  return (
    <>
      <QuotumUsageDataGrid
        {...gridProps}
        plugins={[setFilterModelPlugin]}
      />
    </>
  );
};
