import { cloneDeep, snakeCase } from "lodash";
import { AliasMap } from "./AliasMap.js";
import lookupAliases from "./lookupAliases.js";
import { AgDateFilterCondition } from "../AgGrid.js";
import { DateTime } from "luxon";

// Tweaks the filter from an AgGrid component into the format expected by the Core service
export function prepareFilter(filterModel: any, aliasMap?: AliasMap) {
  const filters: any = {};

  // If we have nested fields under a schema node, like { batch_items: { batch_id : { ... }}}
  // then we want to remove the schema node and inject the schema name into all the field nodes, like { "batch_items.batch_id": { ... }}
  // We will know if node is a schema or a field filter based upon the presence or absence of the filterType property.
  // TO DO: Update the server to work with nested schema/field nodes in the filter.
  for (const schema in filterModel) {
    if (typeof schema !== 'string') {
      continue;
    }

    const data = filterModel[schema];

    // if it is undefined, exclude it from the filters.
    if (data === undefined) {
      continue;
    }

    // if it contains a filterType, then this is a filter node - not a schema node.
    // For now, just copy it over to the filters object.
    if ("filterType" in data) {
      // RTK Query appears to freeze objects - and so we need to perform a deepClone of the data
      // so that we can manipulate it without error
      filters[schema] = cloneDeep(data);
      continue;
    }

    // it is a schema node. For each child node, create a filter node in the filters array.
    // join the schema name to each field.
    for (const field in data) {
      if (typeof field !== 'string') {
        continue;
      }

      filters[`${schema}.${field}`] = data[field];
    }
  }

  // massage each filter into the format expected by the core service
  for (const field in filters) {
    if (typeof field !== 'string') {
      continue;
    }

    const filter = filters[field];

    if (filter.conditions) {
      filter.condition1 = filter.conditions[0];
      filter.condition2 = filter.conditions[1];
      delete filter.conditions;
    }

    // if "operator" is present, then the filter is configured with dual conditionals.
    // this is the expected format - we can just copy it over.
    if ("operator" in filter) {
      filters[field] = { ...filter };
      if (filters[field].condition2?.filterType) {
        delete filters[field].condition2.filterType;
      }
    } else if (!("condition1" in filter)) {
      filters[field] = {
        filterType: filter.filterType,
        condition1: { ...filter },
        //operator: undefined,
        //condition2: undefined,
      };
    }
    delete filters[field].condition1.filterType;

    if (filters[field].filterType === "date") {
      prepareFitlerDateCondition(filters[field].condition1);
      if (filters[field].condition2) {
        prepareFitlerDateCondition(filters[field].condition2);
      }
    }
  }

  // TO DO: we should generalize how the progress field is handled
  // The UI displays progress 0% to 100%
  // But the database stores the progress as a floating value between 0 and 1
  // As such, modify the filter values by dividing by 100 before sending to the server
  const { progress } = filters;
  if (progress) {
    if (progress.condition1.filter) { progress.condition1.filter /= 100.0; }
    if (progress.condition1.filterTo) { progress.condition1.filterTo /= 100.0; }
    if (progress.condition2?.filter) { progress.condition2.filter /= 100.0; }
    if (progress.condition2?.filterTo) { progress.condition2.filterTo /= 100.0; }
  }

  // We need to modify various field and schema names before sending
  for (const fieldName in filters) {
    if (typeof fieldName !== 'string') {
      continue;
    }

    const parts = lookupAliases(aliasMap, fieldName.split(".")).map(snakeCase);
    let updatedFieldName = parts.pop()!;
    if (parts.length) {
      updatedFieldName = `${parts.join("_")}.${updatedFieldName}`;
    }

    if (updatedFieldName !== fieldName) {
      filters[updatedFieldName] = filters[fieldName];
      delete filters[fieldName];
    }
  }

  return filters;
}

/** Pass in a condition node from a filter with filterType:"date" and it will handle converting
 * it over to what is expected in Core. The default Date filter UI provided by AgGrid only supports
 * specifying a date, not a time or timezone. But Core supports providing a date/time in ISO. If
 * we just pass the filter conditions as-is to Core, this can lead to unexpected results for the
 * end-user. 
 * 
 * Additionally, we need to convert all dates from the user's local timezone to UTC.
 */
function prepareFitlerDateCondition(condition: AgDateFilterCondition) {
  let from = DateTime.fromJSDate(new Date(condition.dateFrom!), { zone: "local" });
  let to = condition.dateTo ? DateTime.fromJSDate(new Date(condition.dateTo), { zone: "local" }) : undefined;

  /* eslint-disable no-fallthrough */
  switch (condition.type) {
  case "notEqual":
    condition.type = "outOfRange";
    to = from;
  
  /** Note: the 'outOfRange' condition type isn't something built-in to the AgGrid */
  case "outOfRange": 
    from = from.startOf('day').toUTC();
    to = to!.endOf('day').toUTC();
    condition.dateFrom = from.toISO();
    condition.dateTo = to.toISO();
    break;

  case "equals": 
    condition.type = "inRange";
    to = from;

  case "inRange": 
    from = from.startOf('day').toUTC();
    to = to!.endOf('day').toUTC();
    condition.dateFrom = from.toISO();
    condition.dateTo = to.toISO();
    break;
    
  case "lessThan":
  case "lessThanOrEqual": 
    condition.dateFrom = from.startOf('day').toUTC().toISO();
    condition.dateTo = undefined;
    break;
    
  /** Convert to greater than the end of the day */
  case "greaterThan": 
  case "greaterThanOrEqual":
    condition.dateFrom = from.endOf("day").toUTC().toISO();
    condition.dateTo = undefined;
    break;

  default:
    console.error(`unhandled date filter type: ${JSON.stringify(condition.type)}`);
    break;
  }
  /* eslint-enable no-fallthrough */
}

export default prepareFilter;
